@import url("https://fonts.googleapis.com/css2?family=Inria+Serif:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Paprika&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@keyframes slide-in {
    0% {
        right: -100%;
        opacity: 0;
    }
    10% {
        right: 0;
        opacity: 1;
    }
    90% {
        right: 0;
        opacity: 1;
    }
    100% {
        right: 100%;
        opacity: 0;
    }}